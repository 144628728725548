import { combineReducers } from "redux";
import { loadingBarReducer } from "react-redux-loading-bar";

import init from "./reducers";

function app(state = {}, action) {
  const type = action.type;
  switch (type) {
    default:
      return state;
  }
}

export default combineReducers({
  app,
  init,
  loadingBar: loadingBarReducer,
});
