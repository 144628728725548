import Dashboard from "views/Dashboard/Dashboard.js";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import Image from "@material-ui/icons/Image";
import RegisterPage from "views/Pages/Td_RegisterPage";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: Image,
    state: "pageCollapse",
    views: [
      {
        path: "/register-page",
        name: "REGISTRO",
        mini: "R",
        component: RegisterPage,
        layout: "/auth",
      },
    ],
  },
];
export default dashRoutes;
