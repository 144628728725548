import Utils from "../assets/utils/Utils.js";

// Actions
import {
  RESET_APP_STATE,
  RESPONSE_CLIENT_REGISTER,
  RESPONSE_SEND_CODE,
  RESPONSE_VALID_CODE,
} from "../actions/actions";

import initialState from "../state";

export default function init(state = initialState, action) {
  const type = action.type;

  switch (type) {
    case RESET_APP_STATE: {
      localStorage.removeItem(Utils.constants.KEY_LS_USER);
      let config = state.config;
      initialState.config = config;
      initialState.reset = true;
      return initialState;
    }
    case RESPONSE_CLIENT_REGISTER:
      return Object.assign({}, state, { clientRegister: action.data });
    case RESPONSE_SEND_CODE:
      return Object.assign({}, state, { sendCode: action.data });
    case RESPONSE_VALID_CODE:
      return Object.assign({}, state, { validCode: action.data });
    default:
      return state;
  }
}
