import axios from "axios";

import messagesEN from "./en.json";
import messagesES from "./es.json";

const constants = {
  HOST_API: "http://74.208.135.181/tdapp/api",
  version: "0.0.20210709",
  MIN_PASSWORD: 8,
  KEY_LS_USER: "user_sys_tijera_dorada",
  KEY_LS_TOKEN: "token_sys_tijera_dorada",
  status: {
    ok: "OK",
    warning: "WARKING",
    error: "ERROR",
  },
};

const isNumeric = (val) => {
  var numeric = true;
  var chars = "0123456789+";
  var len = val.length;
  var char = "";
  for (let i = 0; i < len; i++) {
    char = val.charAt(i);
    if (chars.indexOf(char) === -1) numeric = false;
  }
  return numeric;
};

const isDouble = (val) => {
  var numeric = true;
  var chars = "0123456789.+";
  var len = val.length;
  var char = "";
  for (let i = 0; i < len; i++) {
    char = val.charAt(i);
    if (chars.indexOf(char) === -1) numeric = false;
  }
  return numeric;
};

const isEmpty = (obj) => {
  try {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  } catch (e) {
    return true;
  }
};

const formatDate = (x) => {
  try {
    let date = x.split("T");
    date = date[0] + " - " + date[1].split("Z")[0];
    return date;
  } catch (e) {
    return x;
  }
};

const numberWithCommas = (x) => {
  return Number.parseFloat(x)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const validateEmail = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const isUserLoggedIn = () => {
  let token = JSON.parse(localStorage.getItem(constants.KEY_LS_TOKEN));
  if (isEmpty(token)) {
    return false;
  }
  return true;
};

const isIframe = () => {
  if (window.location !== window.parent.location) {
    return true;
  } else {
    return false;
  }
};

const getMetadata = async () => {
  let user = null;

  if (isUserLoggedIn()) {
    user = JSON.parse(localStorage.getItem(constants.KEY_LS_TOKEN)).data;
  }

  const metadata = {
    ip: "",
    user: user,
    appCodeName: window.navigator.appCodeName,
    appName: window.navigator.appName,
    appVersion: window.navigator.appVersion,
    cookieEnabled: window.navigator.cookieEnabled,
    deviceMemory: window.navigator.deviceMemory,
    hardwareConcurrency: window.navigator.hardwareConcurrency,
    language: window.navigator.language,
    platform: window.navigator.platform,
    product: window.navigator.product,
    productSub: window.navigator.productSub,
    userAgent: window.navigator.userAgent,
    vendor: window.navigator.vendor,
    vendorSub: window.navigator.vendorSub,
    screen: {
      height: window.screen.height,
      width: window.screen.width,
      availHeight: window.screen.availHeight,
      availWidth: window.screen.availWidth,
      availTop: window.screen.availTop,
      availLeft: window.screen.availLeft,
      pixelDepth: window.screen.pixelDepth,
    },
  };

  //return metadata
  try {
    const response = await axios("https://api.ipify.org?format=json");
    const data = response.data;
    metadata.ip = data.ip;
    return metadata;
  } catch (err) {
    return metadata;
  }
};

const create_UUID = () => {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
};

const checkLanguage = () => {
  const userLang = navigator.language || navigator.userLanguage;
  const lang = userLang.split("-")[0];
  return lang;
};

const getSignoMoney = () => {
  return "$";
};

const getMessages = () => {
  if (checkLanguage().toLowerCase() !== "es") return messagesEN;
  else return messagesES;
};

const mpOrdenToDate = (mpOrden) => {
  if (mpOrden === undefined) return new Date();

  const year = mpOrden.slice(0, 4);
  const month = mpOrden.slice(4, 6);
  const day = mpOrden.slice(6, 2);
  return new Date(year + "/" + month + "/" + day);
};

const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const groupBy2 = (xs, key, key2) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key][key2]] = rv[x[key][key2]] || []).push(x);
    return rv;
  }, {});
};

const distinct = (value, index, self) => {
  if (value.id) {
    return self.indexOf(value.id) === index;
  } else {
    return self.indexOf(value) === index;
  }
};

const orderByAsc = (data, field) => {
  data.sort(function (a, b) {
    if (a[field] > b[field]) {
      return 1;
    }
    if (a[field] < b[field]) {
      return -1;
    }
    return 0;
  });
  return data;
};

const orderByDesc = (data, field) => {
  data.sort(function (a, b) {
    if (a[field] > b[field]) {
      return -1;
    }
    if (a[field] < b[field]) {
      return 1;
    }
    return 0;
  });
  return data;
};

const arrayBufferToBase64 = (buffer) => {
  let TYPED_ARRAY = new Uint8Array(buffer);
  const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
    return data + String.fromCharCode(byte);
  }, "");
  return btoa(STRING_CHAR);
};

const configurablesAsueto = [
  [1], //ENERO
  [1, 5], //FEBRERO
  [15], //MARZO
  [], //ABRIL
  [1], //MAYO
  [], //JUNIO
  [], //JULIO
  [], //AGOSTO
  [16], //SEPTIEMBRE
  [], //OCTUBRE
  [15], //NOVIEMBRE
  [25], //DICIEMBRE
];
const calculaFecha = (diaPedido, diasPactados, diasSemanaLabarables) => {
  if (!Array.isArray(diasSemanaLabarables)) {
    diasSemanaLabarables = [1, 2, 3, 4, 5]; //default, lunes a viernes
  }
  const diasAsueto = configurablesAsueto;
  let diaPropuesto = new Date(
    diaPedido.getFullYear(),
    diaPedido.getMonth(),
    diaPedido.getDate()
  );

  // iniciamos un contador en 1 para calcular la fecha de diaPropuesto.
  let i = 1;
  // El siguiente bucle se ejecutará hasta que se consuman los diasPactados
  while (diasPactados > 0) {
    // En cada iteración asumimos que el día no es festivo
    let festivo = false;
    // incrementamos en 1 el diaPropuesto para efectuar las verificaciones
    diaPropuesto = new Date(
      diaPedido.getFullYear(),
      diaPedido.getMonth(),
      diaPedido.getDate() + i
    );
    if (diasSemanaLabarables.includes(diaPropuesto.getDay())) {
      let m = diaPropuesto.getMonth();
      let dia = diaPropuesto.getDate();

      for (let d in diasAsueto[m]) {
        if (dia === diasAsueto[m][d]) {
          festivo = true;
          //Aquí no hay mucho que aclarar, si es festivo ya no sigo comprobando el resto del mes.
          break;
        }
      } // Fin bucle for

      if (!festivo) {
        // Si las condiciones son: Laborable y No Festivo, descuento el diaPactado.
        diasPactados--;
      }
    }

    // Por último, debemos incrementar el número de días que sumaremos al diaPropuesto
    // en cada iteración
    i++;
  } // Fin de bucle while
  // Devolvemos el resultado
  return diaPropuesto;
}; // Fin función

const scrollTop = () => {
  window.scrollTo(0, 0);
};

export default {
  create_UUID: create_UUID,
  constants: constants,
  calculaFecha,
  orderByAsc,
  isIframe,
  arrayBufferToBase64,
  orderByDesc,
  groupBy,
  groupBy2,
  distinct,
  messages: messagesES,
  getSignoMoney,
  isNumeric,
  isDouble,
  isEmpty,
  formatDate,
  numberWithCommas,
  validateEmail,
  isUserLoggedIn,
  getMetadata,
  checkLanguage,
  getMessages,
  scrollTop,
  mpOrdenToDate,
};
