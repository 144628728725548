import React, { Component } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";

// @material-ui/core components
import { withTheme, withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

// react component plugin for creating a beautiful datetime dropdown picker
import Datetime from "react-datetime";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import PhonelinkEraseIcon from "@material-ui/icons/PhonelinkErase";
import VpnKeyIcon from "@material-ui/icons/VpnKey";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import PropTypes from "prop-types";

import AddAlert from "@material-ui/icons/AddAlert";

import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import Utils from "../../assets/utils/Utils";
import { requestClientRegister, requestSendCode } from "actions/actions";
import tijeraDorada from "assets/img/tijeraDoradaTransparent.png";

import { Helmet } from "react-helmet";
import { requestValidCode } from "actions/actions";

const cities = [
  {
    id: 5,
    text: "Hermosillo",
  },
  {
    id: 2,
    text: "San Jose Del Cabo",
  },
];
const branchesoffices = [
  {
    id: 50,
    cityId: 5,
    text: "Soriana Encinas",
  },
  {
    id: 49,
    cityId: 5,
    text: "Soriana Quiroga",
  },
  {
    id: 53,
    cityId: 5,
    text: "Soriana Bachoco",
  },
  {
    id: 56,
    cityId: 5,
    text: "Soriana Progreso",
  },
  {
    id: 34,
    cityId: 2,
    text: "Soriana Guaimitas",
  },
  {
    id: 70,
    cityId: 2,
    text: "Soriana Caribe",
  },
];
const defaultCity = cities[0];
const defaultbranchoffice = branchesoffices[0];

class Td_RegisterPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cardAnimaton: false,
      name: "",
      secondName: "",
      secondName2: "",
      branchoffice: {},
      city: {},
      movil: "",
      codeValidation: "",
      registerFormName: "",
      registerFormSecondName: "",
      registerFormMovil: "",
      birthday: "",
      validForm: true,
      done: false,
      snackBar: {
        message: "",
        color: "info",
        open: false,
        place: "tc",
      },
      phoneValidated: false,
      countDown: 0,
      codeSent: false,
      defaultbranchofficeState: branchesoffices.filter(
        (b) => b.cityId === defaultCity.id
      ),
    };
    this.nameRef = React.createRef();
    this.codeConfirmationRef = React.createRef();
    this.cardRef = React.createRef();

    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
    this.handleOpenSnackbar = this.handleOpenSnackbar.bind(this);
    this.handleOnChangeCity = this.handleOnChangeCity.bind(this);
    this.handleOnChangeBranchOffice = this.handleOnChangeBranchOffice.bind(
      this
    );
    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleClickRegister = this.handleClickRegister.bind(this);
    this._isValid = this._isValid.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.clear = this.clear.bind(this);
    this.restCountDown = this.restCountDown.bind(this);
    this.startCountDown = this.startCountDown.bind(this);
    this.handleSendConfimation = this.handleSendConfimation.bind(this);
    this.handleConfirmCode = this.handleConfirmCode.bind(this);
  }

  clear() {
    this.setState({
      name: "",
      secondName: "",
      secondName2: "",
      branchoffice: defaultbranchoffice,
      city: defaultCity,
      movil: "",
      registerFormName: "",
      registerFormSecondName: "",
      registerFormMovil: "",
      birthday: "",
      validForm: true,
    });
  }

  componentDidMount() {
    this.clear();
    this.setState({ done: false });
    this.nameRef.current.focus();
    this.cardRef.current.scrollIntoView();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.init.clientRegister !== this.props.init.clientRegister) {
      const { IsValid } = this.props.init.clientRegister;
      if (IsValid) {
        this.setState({ done: true });
        this.clear();
      } else {
        this.setState({ done: false });
        this.handleOpenSnackbar({
          message: "No se pudo realizar el registro.",
          color: "warning",
        });
      }
    }
    if (prevProps.init.sendCode !== this.props.init.sendCode) {
      const { IsValid, Message } = this.props.init.sendCode;
      if (IsValid) {
        this.setState({ codeSent: true });
        if (this.codeConfirmationRef.current) {
          this.codeConfirmationRef.current.focus();
        }
      } else {
        this.handleOpenSnackbar({ message: Message });
      }
    }
    if (prevProps.init.validCode !== this.props.init.validCode) {
      const { IsValid, Message } = this.props.init.validCode;
      if (IsValid) {
        this.setState({
          phoneValidated: true,
        });
        this.handleClickRegister();
      } else {
        this.handleOpenSnackbar({ message: Message });
      }
    }
  }
  componentWillUnmount() {
    console.log("clear");
    clearInterval(this.restCountDown);
  }

  handleOpenSnackbar(dataSnack) {
    const { snackBar } = this.state;
    snackBar.open = true;
    snackBar.message = dataSnack.message;
    snackBar.color = dataSnack.color || "info";
    snackBar.place = dataSnack.place || "tc";
    this.setState({
      snackBar: snackBar,
    });
  }

  handleCloseSnackbar() {
    const { snackBar } = this.state;
    snackBar.open = false;
    this.setState({
      snackBar: snackBar,
    });
  }

  handleChangeText(e) {
    const name = e.target.name;
    const val = e.target.value;
    var isValid = true;
    if (name === "movil") {
      if (val.length === 10) {
        this.setState({ registerFormMovil: "success" });
      } else {
        this.setState({ registerFormMovil: "error" });
        if (val.length > 10) {
          this.setState({ registerFormMovil: "success" });
          return;
        }
      }
      if (!Utils.isNumeric(val)) isValid = false;
      // this._isValid();
      if (!isValid) return;
    } else if (name === "name") {
      this.setState({ registerFormName: "error" });
      if (!val) isValid = false;
      // this._isValid();
      if (!isValid) return;
      this.setState({ registerFormName: "success" });
    } else if (name === "secondName") {
      this.setState({ registerFormSecondName: "error" });
      if (!val) isValid = false;
      // this._isValid();
      if (!isValid) return;
      this.setState({ registerFormSecondName: "success" });
    }
    this.setState({ [name]: val });
  }
  handleOnChangeCity(val) {
    this.setState({
      city: val,
      defaultbranchofficeState: branchesoffices.filter(
        (b) => b.cityId === val.id
      ),
      branchoffice: branchesoffices.filter((b) => b.cityId === val.id)[0],
    });
    // this._isValid();
  }
  handleChangeDate(val) {
    this.setState({ birthday: val });
    // this._isValid();
  }

  handleOnChangeBranchOffice(val) {
    this.setState({ branchoffice: val });
    // this._isValid();
  }

  _isValid() {
    const {
      registerFormMovil,
      registerFormSecondName,
      registerFormName,
      birthday,
    } = this.state;
    if (
      registerFormMovil === "success" &&
      registerFormSecondName === "success" &&
      registerFormName === "success" &&
      birthday
    ) {
      // this.setState({ validForm: true });
      return true;
    } else {
      // this.setState({ validForm: false });
      return false;
    }
  }

  handleClickRegister() {
    const {
      city,
      branchoffice,
      movil,
      name,
      secondName,
      secondName2,
      birthday,
    } = this.state;

    const data = {
      Token: "5019dca0855d3e282330e1f585facca8",
      Nombres: name,
      ApellidoPaterno: secondName,
      ApellidoMaterno: secondName2,
      CiudadId: city.id,
      ColoniaId: 499,
      SucursalId: branchoffice.id,
      Sellos: 6,
      TelefonoCelular: movil,
      fechaNacimiento: birthday,
    };
    if (this._isValid()) {
      this.props.requestClientRegister(data);
    } else {
      this.handleOpenSnackbar({
        message: "Faltan datos en el formulario por llenar",
      });
    }
  }
  handleSendConfimation() {
    const { movil } = this.state;
    this.startCountDown();
    this.props.requestSendCode({
      TelefonoCelular: movil,
      Token: "5019dca0855d3e282330e1f585facca8",
    });
  }
  handleConfirmCode() {
    const { codeValidation, movil } = this.state;
    if (codeValidation) {
      this.props.requestValidCode({
        TelefonoCelular: movil,
        pin: codeValidation,
        Token: "5019dca0855d3e282330e1f585facca8",
      });
    } else {
      this.handleOpenSnackbar({ message: "Ingresa el PIN para continuar." });
    }
  }

  startCountDown() {
    const iniCountDown = 30;
    this.setState({
      countDown: iniCountDown,
    });
    setInterval(this.restCountDown, 1000);
  }
  restCountDown() {
    const { countDown } = this.state;
    const countDownResult = countDown - 1;
    if (countDownResult === -1) {
      clearInterval(this.restCountDown);
    } else {
      this.setState({
        countDown: countDownResult,
      });
    }
  }
  render() {
    const { classes } = this.props;
    const {
      city,
      branchoffice,
      movil,
      codeValidation,
      name,
      secondName,
      secondName2,
      birthday,
      registerFormName,
      registerFormMovil,
      registerFormSecondName,
      validForm,
      done,
      snackBar,
      phoneValidated,
      countDown,
      codeSent,
      defaultbranchofficeState,
    } = this.state;

    return (
      <div className={classes.container}>
        <Helmet>
          <title>Regsitro - Tijera Dorada</title>
        </Helmet>
        <GridContainer justify="center" className={classes.customContainer}>
          <GridItem xs={12} sm={12} md={10}>
            <Card className={classes.cardSignup}>
              <img
                ref={this.cardRef}
                style={{
                  height: "300px",
                  maxWidth: "400px",
                  width: "100%",
                  alignSelf: "center",
                }}
                src={tijeraDorada}
                alt="Logo"
              />
              {/* <h2 className={classes.cardTitle}>Registro</h2> */}
              <CardBody>
                {!done ? (
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <form className={classes.form}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              error={registerFormName === "error"}
                              success={registerFormName === "success"}
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                              }}
                              inputProps={{
                                inputRef: this.nameRef,
                                name: "name",
                                value: name,
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Face
                                      className={classes.inputAdornmentIcon}
                                    />
                                  </InputAdornment>
                                ),
                                placeholder: "Nombre(s)",
                                onChange: this.handleChangeText,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <CustomInput
                              error={registerFormSecondName === "error"}
                              success={registerFormSecondName === "success"}
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                              }}
                              inputProps={{
                                name: "secondName",
                                value: secondName,
                                placeholder: "Apellido Paterno",
                                onChange: this.handleChangeText,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                              }}
                              inputProps={{
                                name: "secondName2",
                                value: secondName2,
                                placeholder: "Apellido Materno",
                                onChange: this.handleChangeText,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12} lg={12}>
                            <CardBody>
                              <InputLabel className={classes.label}>
                                Fecha de Nacimiento
                              </InputLabel>
                              <br />
                              <FormControl fullWidth>
                                <Datetime
                                  timeFormat={false}
                                  inputProps={{
                                    placeholder: "DD/MM/YYYY",
                                  }}
                                  dateFormat={"DD/MM/YYYY"}
                                  closeOnSelect
                                  onChange={this.handleChangeDate}
                                  value={birthday}
                                />
                              </FormControl>
                            </CardBody>
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6} lg={6}>
                            <CustomDropdown
                              buttonText={"Ciudad: " + city.text}
                              hoverColor="info"
                              buttonProps={{
                                round: true,
                                block: true,
                                color: "info",
                              }}
                              dropPlacement="bottom"
                              dropdownList={cities}
                              onClick={this.handleOnChangeCity}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6} lg={6}>
                            <CustomDropdown
                              buttonText={"Sucursal: " + branchoffice.text}
                              hoverColor="info"
                              buttonProps={{
                                round: true,
                                block: true,
                                color: "info",
                              }}
                              dropPlacement="bottom"
                              dropdownList={defaultbranchofficeState}
                              onClick={this.handleOnChangeBranchOffice}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              error={registerFormMovil === "error"}
                              success={registerFormMovil === "success"}
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                              }}
                              inputProps={{
                                name: "movil",
                                value: movil,
                                disabled: codeSent,
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    {phoneValidated ? (
                                      <PhoneAndroidIcon
                                        className={classes.inputAdornmentIcon}
                                      />
                                    ) : (
                                      <PhonelinkEraseIcon
                                        className={classes.inputAdornmentIcon}
                                      />
                                    )}
                                  </InputAdornment>
                                ),
                                placeholder: "Movil",
                                onChange: this.handleChangeText,
                                onKeyPress: this.handleChangeText,
                              }}
                            />
                          </GridItem>
                          {codeSent && !phoneValidated ? (
                            <GridItem xs={12} sm={12} md={12}>
                              <CustomInput
                                error={registerFormMovil === "error"}
                                success={registerFormMovil === "success"}
                                formControlProps={{
                                  fullWidth: true,
                                  className: classes.customFormControlClasses,
                                }}
                                inputProps={{
                                  inputRef: this.codeConfirmationRef,
                                  name: "codeValidation",
                                  value: codeValidation,
                                  startAdornment: (
                                    <InputAdornment
                                      position="start"
                                      className={classes.inputAdornment}
                                    >
                                      <VpnKeyIcon
                                        className={classes.inputAdornmentIcon}
                                      />
                                    </InputAdornment>
                                  ),
                                  placeholder: "PIN",
                                  onChange: this.handleChangeText,
                                  onKeyPress: this.handleChangeText,
                                }}
                              />
                            </GridItem>
                          ) : null}
                        </GridContainer>
                        <div className={classes.center}>
                          {phoneValidated ? (
                            <Button
                              round
                              color="primary"
                              onClick={this.handleClickRegister}
                              disabled={!validForm}
                            >
                              Registrar
                            </Button>
                          ) : (
                            <div>
                              {codeSent ? (
                                <Button
                                  round
                                  color="primary"
                                  onClick={this.handleConfirmCode}
                                  disabled={!codeSent && !codeValidation}
                                >
                                  Validar Celular
                                </Button>
                              ) : null}
                              {registerFormMovil === "success" ? (
                                <Button
                                  round
                                  color={countDown === 0 ? "info" : "secondary"}
                                  onClick={this.handleSendConfimation}
                                  disabled={countDown !== 0}
                                >
                                  {countDown === 0
                                    ? "Enviar Código"
                                    : "Re-Enviar en (" + countDown + ")"}
                                </Button>
                              ) : null}
                            </div>
                          )}
                        </div>
                      </form>
                    </GridItem>
                  </GridContainer>
                ) : (
                  <h3 className={classes.cardTitle}>
                    Te has registrado correctamente.
                  </h3>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Snackbar
          place={snackBar.place}
          color={snackBar.color}
          icon={AddAlert}
          message={snackBar.message}
          open={snackBar.open}
          closeNotification={this.handleCloseSnackbar}
          close
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => {
  return {
    requestClientRegister: (data) => {
      dispatch(requestClientRegister(data));
    },
    requestSendCode: (data) => {
      dispatch(requestSendCode(data));
    },
    requestValidCode: (data) => {
      dispatch(requestValidCode(data));
    },
  };
};

Td_RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  requestClientRegister: PropTypes.func,
  requestSendCode: PropTypes.func,
  requestValidCode: PropTypes.func,
  login: PropTypes.object,
  init: PropTypes.object,
  history: PropTypes.array,
};

export default compose(
  withRouter,
  withTheme,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Td_RegisterPage);
