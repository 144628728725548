/*!

=========================================================
* Material Dashboard PRO React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Auth from "layouts/Auth";

import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";
import { Provider } from "react-redux";
import store from "store";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path="/auth" component={Auth} />
        <Redirect from="/" to="/auth/register-page" />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
