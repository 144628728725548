export const RESET_APP_STATE = "RESET_APP_STATE";

export const resetAppState = () => ({
  type: RESET_APP_STATE,
});

export const REQUEST_CLIENT_REGISTER = "REQUEST_CLIENT_REGISTER";
export const RESPONSE_CLIENT_REGISTER = "RESPONSE_CLIENT_REGISTER";

export const requestClientRegister = (data) => ({
  type: REQUEST_CLIENT_REGISTER,
  data,
});
export const responseClientRegister = (data) => ({
  type: RESPONSE_CLIENT_REGISTER,
  data,
});

export const REQUEST_SEND_CODE = "REQUEST_SEND_CODE";
export const RESPONSE_SEND_CODE = "RESPONSE_SEND_CODE";

export const requestSendCode = (data) => ({
  type: REQUEST_SEND_CODE,
  data,
});
export const responseSendCode = (data) => ({
  type: RESPONSE_SEND_CODE,
  data,
});

export const REQUEST_VALID_CODE = "REQUEST_VALID_CODE";
export const RESPONSE_VALID_CODE = "RESPONSE_VALID_CODE";

export const requestValidCode = (data) => ({
  type: REQUEST_VALID_CODE,
  data,
});
export const responseValidCode = (data) => ({
  type: RESPONSE_VALID_CODE,
  data,
});
