import Utils from "../assets/utils/Utils";
import axios from "axios";

//Save Client Register
export const saveClientRegisterAPI = async (obj) => {
  try {
    let options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      Utils.constants.HOST_API + "/Catalogos/RegistrarCliente/RegistrarCliente",
      obj.data,
      options
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const sendCodeAPI = async (obj) => {
  console.log("API");
  try {
    let options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      Utils.constants.HOST_API + "/Catalogos/EnvioSMS/EnvioSMS",
      obj.data,
      options
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const validarCodigoAPI = async (obj) => {
  console.log("API");
  try {
    let options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      Utils.constants.HOST_API + "/Catalogos/ValidarPin/ValidarPin",
      obj.data,
      options
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
