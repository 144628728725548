import { call, put, all, takeLatest } from "redux-saga/effects";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { saveClientRegisterAPI, sendCodeAPI, validarCodigoAPI } from "./api";

import {
  REQUEST_CLIENT_REGISTER,
  REQUEST_SEND_CODE,
  REQUEST_VALID_CODE,
  responseClientRegister,
  responseSendCode,
  responseValidCode,
} from "../actions/actions";

function* saveClintRegister(action) {
  try {
    yield put(showLoading());
    const data = yield call(saveClientRegisterAPI, action);
    yield put(responseClientRegister(data));
  } catch (error) {
    yield put(hideLoading());
  } finally {
    yield put(hideLoading());
  }
}

function* sendCode(action) {
  try {
    yield put(showLoading());
    const data = yield call(sendCodeAPI, action);
    yield put(responseSendCode(data));
  } catch (error) {
    yield put(hideLoading());
  } finally {
    yield put(hideLoading());
  }
}

function* validarCodigo(action) {
  try {
    yield put(showLoading());
    const data = yield call(validarCodigoAPI, action);
    yield put(responseValidCode(data));
  } catch (error) {
    yield put(hideLoading());
  } finally {
    yield put(hideLoading());
  }
}

export default function* mySaga() {
  yield all([
    takeLatest(REQUEST_CLIENT_REGISTER, saveClintRegister),
    takeLatest(REQUEST_SEND_CODE, sendCode),
    takeLatest(REQUEST_VALID_CODE, validarCodigo),
  ]);
}
